import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./OptionChainTable.scss";
import { Context } from "../../context";

function OptionChainTable() {
  const { instrumentKey, APPURL } = useContext(Context);

  const [optionChainData, setOptionChainData] = useState([]);
  const [leftHeadings, setLeftHeadings] = useState([]);
  const [rightHeadings, setRightHeadings] = useState([]);
  const [centerHeadings, setCenterHeadings] = useState(["Strike"]);

  useEffect(() => {
    const fetchOptionChainData = async () => {
      try {
        const response = await axios.get(
          APPURL + `option/chain/${instrumentKey}`
        );
        const data = response.data.data;
        console.log(data);
        setOptionChainData(data);

        const headingsOrder = [
          "Vol",
          "IV",
          "Vega",
          "Gamma",
          "Theta",
          "Delta",
          "OI (chg)",
          "OI (lakhs)",
          "LTP",
          "Strike",
          "LTP",
          "OI (lakhs)",
          "OI (chg)",
          "Delta",
          "Theta",
          "Gamma",
          "Vega",
          "IV",
          "Vol",
        ];

        const leftPart = headingsOrder.slice(0, 9);
        const centerPart = headingsOrder.slice(9, 10);
        const rightPart = headingsOrder.slice(10);

        setLeftHeadings(leftPart);
        setCenterHeadings(centerPart);
        setRightHeadings(rightPart);
      } catch (error) {
        console.error("Error fetching option chain data:", error);
      }
    };
    fetchOptionChainData();

    
  }, [instrumentKey, APPURL]);

  return (
    <div className="option-chain-table">
      {optionChainData.length > 0 ? (
        ""
      ) : (
        <div>Table Not Available For This Company</div>
      )}
      <div className="tables-container">
        <div className="table-responsive">
          <table className="table table-light table-striped table-hover table-bordered">
            <thead>
              <tr>
                {leftHeadings.map((heading, index) => (
                  <th key={index}>{heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {optionChainData.map((option, index) => (
                <tr key={index}>
                  {leftHeadings.map((heading, index) => (
                    <td key={index}>
                      {heading === "Vol" &&
                        option.call_options.market_data.volume}
                      {heading === "IV" && option.call_options.option_greeks.iv}
                      {heading === "Vega" &&
                        option.call_options.option_greeks.vega}
                      {heading === "Gamma" &&
                        option.call_options.option_greeks.gamma}
                      {heading === "Theta" &&
                        option.call_options.option_greeks.theta}
                      {heading === "Delta" &&
                        option.call_options.option_greeks.delta}
                      {heading === "OI (chg)" &&
                        option.call_options.market_data.prev_oi}
                      {heading === "OI (lakhs)" &&
                        option.call_options.market_data.oi}
                      {heading === "LTP" && option.call_options.market_data.ltp}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-responsive strike">
          <table className="table table-light table-striped table-hover table-bordered">
            <thead className="">
              <tr className="">
                <th className="">{centerHeadings[0]}</th>
              </tr>
            </thead>
            <tbody>
              {optionChainData.map((option, index) => (
                <tr key={index}>
                  <td>{option.strike_price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-responsive">
          <table className="table table-light table-striped table-hover table-bordered">
            <thead>
              <tr>
                {rightHeadings.map((heading, index) => (
                  <th key={index}>{heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {optionChainData.map((option, index) => (
                <tr key={index}>
                  {rightHeadings.map((heading, index) => (
                    <td key={index}>
                      {heading === "LTP" && option.put_options.market_data.ltp}
                      {heading === "OI (lakhs)" &&
                        option.put_options.market_data.oi}
                      {heading === "OI (chg)" &&
                        option.put_options.market_data.prev_oi}
                      {heading === "Delta" &&
                        option.put_options.option_greeks.delta}
                      {heading === "Theta" &&
                        option.put_options.option_greeks.theta}
                      {heading === "Gamma" &&
                        option.put_options.option_greeks.gamma}
                      {heading === "Vega" &&
                        option.put_options.option_greeks.vega}
                      {heading === "IV" && option.put_options.option_greeks.iv}
                      {heading === "Vol" &&
                        option.put_options.market_data.volume}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default OptionChainTable;
