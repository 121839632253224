import React, { useState, useEffect, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import "./CandlestickChartPage.scss";
import axios from "axios";
import { Context } from "../../context";

const CandlestickChartPage = () => {
  const { instrumentKey, APPURL } = useContext(Context);
  const [candlestickData, setCandlestickData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [dateRange, setDateRange] = useState("1day");
  
 useEffect(() => {
   const fetchData = async () => {
     let interval = "30minute";
     let endpoint = `historical-candle/${instrumentKey}/${interval}`;
     let toDate = new Date();
     let fromDate = new Date();

     switch (dateRange) {
       case "1day":
         interval = "1minute";
         endpoint = `historical-candle/intraday/${instrumentKey}/${interval}`;
         break;
       case "3day":
         break;
       case "1month":
         interval = "day";
         break;
       case "3month":
         interval = "week";
         break;
       case "1year":
         interval = "month";
         break;
       default:
         interval = "30minute";
     }

     if (dateRange !== "1day") {
       switch (dateRange) {
         case "3day":
           fromDate.setDate(toDate.getDate() - 4);
           toDate.setDate(toDate.getDate() - 1);
           break;
         case "1month":
           fromDate.setMonth(toDate.getMonth() - 1);
           break;
         case "3month":
           fromDate.setMonth(toDate.getMonth() - 3);
           break;
         case "1year":
           fromDate.setFullYear(toDate.getFullYear() - 1);
           break;
         default:
           fromDate.setDate(toDate.getDate() - 1);
       }

       try {
         const response = await axios.get(
           `${APPURL}${endpoint}/${formatDate(toDate)}/${formatDate(fromDate)}`
         );
         const candles = response.data.data.candles;
         const seriesData = candles.map((item) => ({
           x: new Date(item[0]),
           y: [item[1], item[2], item[3], item[4]],
         }));
         const seriesDataLinear = candles.map((item) => ({
           x: new Date(item[0]),
           y: item[5],
         }));
         setCandlestickData(seriesData);
         setBarData(seriesDataLinear);
       } catch (error) {
         console.error("Error fetching data:", error);
       }
     } else {
       try {
         const response = await axios.get(`${APPURL}${endpoint}`);
         const candles = response.data.data.candles;
         const seriesData = candles.map((item) => ({
           x: new Date(item[0]),
           y: [item[1], item[2], item[3], item[4]],
         }));
         const seriesDataLinear = candles.map((item) => ({
           x: new Date(item[0]),
           y: item[5],
         }));
         setCandlestickData(seriesData);
         setBarData(seriesDataLinear);
       } catch (error) {
         console.error("Error fetching data:", error);
       }
     }
   };
   fetchData();
 }, [instrumentKey, dateRange, APPURL]);

 const formatDate = (date) => {
   const year = date.getFullYear();
   let month = date.getMonth() + 1;
   let day = date.getDate();

   if (month < 10) {
     month = `0${month}`;
   }
   if (day < 10) {
     day = `0${day}`;
   }

   return `${year}-${month}-${day}`;
 };

 const options = {
   chart: {
     type: "candlestick",
     height: 290,
     id: "candles",
     toolbar: {
       autoSelected: "pan",
       show: true,
     },
     zoom: {
       enabled: true,
     },
   },
   plotOptions: {
     candlestick: {
       colors: {
         upward: "#00B746",
         downward: "#FF0000",
       },
     },
   },
   xaxis: {
     type: "datetime",
     enabled: false,
     show: false,

     //  labels: {
     //    datetimeFormatter: {
     //      year: "yyyy",
     //      month: "MMM 'yy",
     //      day: "dd MMM",
     //      hour: "HH:mm",
     //    },
     //  },
     tooltip: {
       datetimeFormatter: {
         year: "yyyy",
         month: "MMM 'yy",
         day: "dd MMM",
         hour: "HH:mm",
       },
     },
   },
   tooltip: {
     x: {
       format: "dd MMM yyyy, HH:mm",
     },
   },
 };


  const optionsBar = {
    chart: {
      height: 160,
      type: "bar",
      brush: {
        enabled: true,
        target: "candles",
      },
      selection: {
        enabled: true,
        xaxis: {
          min: candlestickData.length > 0 ? candlestickData[0][0] : null,
          max:
            candlestickData.length > 0
              ? candlestickData[candlestickData.length - 1][0]
              : null,
        },
        fill: {
          color: "#ccc",
          opacity: 0.4,
        },
        stroke: {
          color: "#0D47A1",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
        colors: {
          ranges: [
            {
              from: -1000,
              to: 0,
              color: "#F15B46",
            },
            {
              from: 1,
              to: 10000,
              color: "#FEB019",
            },
          ],
        },
      },
    },
    stroke: {
      width: 0,
    },
    xaxis: {
      type: "datetime",
      axisBorder: {
        offsetX: 13,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
  };
  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  return (
    <div className="chart_page">
      <div className="chart-box">
        <div id="chart-candlestick">
          <ReactApexChart
            options={options}
            series={[{ data: candlestickData }]}
            type="candlestick"
            height={290}
          />
        </div>
        <div id="chart-bar">
          <ReactApexChart
            options={optionsBar}
            series={[{ name: "volume", data: barData }]}
            type="bar"
            height={160}
          />
        </div>
      </div>
      <div className="date_buttons">
        <button
          className="btn btn-outline-secondary"
          onClick={() => handleDateRangeChange("1day")}
        >
          1D
        </button>
        <button
          className="btn btn-outline-secondary"
          onClick={() => handleDateRangeChange("3day")}
        >
          3D
        </button>
        <button
          className="btn btn-outline-secondary"
          onClick={() => handleDateRangeChange("1month")}
        >
          1M
        </button>
        <button
          className="btn btn-outline-secondary"
          onClick={() => handleDateRangeChange("3month")}
        >
          3M
        </button>
        <button
          className="btn btn-outline-secondary"
          onClick={() => handleDateRangeChange("1year")}
        >
          1Y
        </button>
      </div>
    </div>
  );
};

export default CandlestickChartPage;
