import React, { useState, useEffect, useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";

import axios from "axios";
import "./MarketQuote.scss";
import { Context } from "../../context";

const MarketQuote = ({ setShowMarketQuote, instrumentKey }) => {
  const [marketData, setMarketData] = useState(null);
  const [index, setIndex] = useState("");
  const [fund, setFund] = useState("");
  const { APPURL } = useContext(Context);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          APPURL + `market-quote/quotes/${instrumentKey}`
        );
        const firstKey = Object.keys(response.data.data)[0];
        const firstValue = response.data.data[firstKey];

        console.log(firstValue);
        setMarketData(firstValue);
        if (firstKey.includes("BSE")) {
          setIndex("BSE");
          setFund("SENSEX");
        } else {
          setIndex("NSE");
          setFund("NIFTY 50");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

  }, [instrumentKey, APPURL]);

  if (!marketData) {
    return null;
  }

  return (
    <div className="quote_panel">
      {marketData && (
        <div className="quote_heading">
          <span onClick={() => setShowMarketQuote(false)}>
            <AiOutlineClose size={20} />
          </span>
          <p className="symbol">
            {marketData.symbol === "NA" ? fund : marketData.symbol}
          </p>
        </div>
      )}
      {marketData && (
        <div className="company_details">
          <div className="company_name">
            <p>{marketData.symbol === "NA" ? fund : marketData.symbol}</p>
            <p className="lower_p">{index}</p>
          </div>
          <div className="company_price">
            <p>{marketData?.last_price}</p>
            <p className="lower_p">{marketData?.net_change}</p>
          </div>
        </div>
      )}
      <div>
        <hr />
        <span>Market Stats</span>
        {marketData && (
          <div className="market_stats">
            <div className="quote">
              <div className="quote_details">
                <p className="upper_p">Open</p>
                <p>{marketData.ohlc.open}</p>
              </div>
              {marketData.upper_circuit_limit && (
                <div className="quote_details">
                  <p className="upper_p">Upper Circuit</p>
                  <p>{marketData.upper_circuit_limit}</p>
                </div>
              )}
              {marketData.volume && (
                <div className="quote_details">
                  <p className="upper_p">Volume</p>
                  <p>{marketData.volume}</p>
                </div>
              )}
              <div className="quote_details">
                <p className="upper_p">Today's Low</p>
                <p>{marketData.ohlc.low}</p>
              </div>
            </div>
            <div className="quote">
              <div className="quote_details">
                <p className="upper_p">Close</p>
                <p>{marketData.ohlc.close}</p>
              </div>
              {marketData.lower_circuit_limit && (
                <div className="quote_details">
                  <p className="upper_p">Lower Circuit</p>
                  <p>{marketData.lower_circuit_limit}</p>
                </div>
              )}
              {marketData.average_price && (
                <div className="quote_details">
                  <p className="upper_p">Average Price</p>
                  <p>{marketData.average_price}</p>
                </div>
              )}
              <div className="quote_details">
                <p className="upper_p high">Today's High</p>
                <p>{marketData.ohlc.high}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      {marketData.depth.buy[0] && (
        <div className="market_depth">
          <hr />
          <span>Market Depth</span>
          <table className="table table-sm  table-light table-hover">
            <thead>
              <tr>
                <th>Quantity</th>
                <th>Bid Price</th>
                <th>Ask Price</th>
                <th>Orders</th>
              </tr>
            </thead>
            <tbody>
              {marketData.depth.buy.map((item, index) => (
                <tr key={index}>
                  <td>{item.quantity}</td>
                  <td>{item.price}</td>
                  <td>{marketData.depth.sell[index].price}</td>
                  <td>{item.orders}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MarketQuote;
