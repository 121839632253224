import { createContext, useState } from "react";

export const Context = createContext();

const AppContext = ({ children }) => {
  const [APPURL, setAPPURL] = useState(
    "https://trading-backend-xh0f.onrender.com/"
    // "http://localhost:3001/"
  );
  const [instrumentKey, setInstrumentKey] = useState("NSE_INDEX|Nifty 50");
  const [showCandlestickChart, setShowCandlestickChart] = useState(true);
  const [showMarketQuote, setShowMarketQuote] = useState(false);
  const [selectedInstrumentKey, setSelectedInstrumentKey] = useState(null);

  return (
    <Context.Provider
      value={{
        APPURL,
        instrumentKey,
        setInstrumentKey,
        showCandlestickChart,
        setShowCandlestickChart,
        showMarketQuote,
        setShowMarketQuote,
        selectedInstrumentKey,
        setSelectedInstrumentKey,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
