import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

const Root = () => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      // window.location.reload();
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  return <App />;
};

ReactDOM.render(<Root />, document.getElementById("root"));