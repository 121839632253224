import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import NSEData from "../complete.json";
import Company from "../Company";
import {
  AiOutlineLineChart,
  AiOutlineBars,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import "./Companies.scss"; 
import { Context } from "../../../context";

function Companies() {
  const {
    setInstrumentKey,
    APPURL,
    setShowCandlestickChart,
    setShowMarketQuote,
    setSelectedInstrumentKey,
  } = useContext(Context);
  const [searchQuery, setSearchQuery] = useState("");
  const [companyData, setCompanyData] = useState({});
  const [niftyData, setNiftyData] = useState(null);
  const [sensexData, setSensexData] = useState(null);
  const [isNiftyHovered, setIsNiftyHovered] = useState(false);
  const [isSensexHovered, setIsSensexHovered] = useState(false);

  const filteredData = NSEData.filter(
    (item) =>
      item.short_name &&
      item.short_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const companiesToRender = searchQuery
    ? filteredData
    : filteredData.slice(0, 20);

  const instrumentKeys = companiesToRender.map(
    (company) => company.instrument_key
  );
  const url = APPURL + `market-quote/quotes/${instrumentKeys.join(",")}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setCompanyData(response.data.data);
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchData();
  }, [url]);

  useEffect(() => {
    const fetchNiftyData = async () => {
      try {
        const response = await axios.get(
          APPURL + `market-quote/quotes/NSE_INDEX|Nifty 50`
        );
        const firstKey = Object.keys(response.data.data)[0];
        const firstValue = response.data.data[firstKey];
        setNiftyData(firstValue);
      } catch (error) {
        console.error("Error fetching Nifty data:", error);
      }
    };

    fetchNiftyData();
  }, []);

  useEffect(() => {
    const fetchSensexData = async () => {
      try {
        const response = await axios.get(
          APPURL + `market-quote/quotes/BSE_INDEX|SENSEX`
        );
        const firstKey = Object.keys(response.data.data)[0];
        const firstValue = response.data.data[firstKey];
        setSensexData(firstValue);
      } catch (error) {
        console.error("Error fetching Sensex data:", error);
      }
    };

    fetchSensexData();
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNiftyMouseEnter = () => {
    setIsNiftyHovered(true);
  };

  const handleNiftyMouseLeave = () => {
    setIsNiftyHovered(false);
  };

  const handleSensexMouseEnter = () => {
    setIsSensexHovered(true);
  };

  const handleSensexMouseLeave = () => {
    setIsSensexHovered(false);
  };
  const handleChart = (id) => {
    setInstrumentKey(id);
    setShowCandlestickChart(true);
    setSelectedInstrumentKey(id);
  };
  const handleOption = (id) => {
    setInstrumentKey(id);
    setShowCandlestickChart(false);
    setSelectedInstrumentKey(id);
  };
  const handleMarketQuote = (id) => {
    setInstrumentKey(id);
    setShowMarketQuote(true);
    setSelectedInstrumentKey(id);
  };

  return (
    <div className="companies">
      <div className="index_funds row">
        <div
          className="index_funds_item col"
          onMouseEnter={handleNiftyMouseEnter}
          onMouseLeave={handleNiftyMouseLeave}
        >
          <div className="company_name">
            <p>NIFTY</p>
            <p className="lower_p">NSE INDEX</p>
          </div>
          {!isNiftyHovered && (
            <div className="company_price">
              <p>{niftyData ? niftyData.last_price : "-"}</p>
              <p className="lower_p">
                {niftyData ? niftyData.net_change : "-"}
              </p>
            </div>
          )}
          {isNiftyHovered && (
            <div className="company_buttons">
              <AiOutlineLineChart
                size={18}
                onClick={() => handleChart("NSE_INDEX|Nifty 50")}
              />
              <AiOutlineBars
                size={18}
                onClick={() => handleOption("NSE_INDEX|Nifty 50")}
              />
              <AiOutlineInfoCircle
                size={18}
                onClick={() => handleMarketQuote("NSE_INDEX|Nifty 50")}
              />
            </div>
          )}
        </div>
        <div
          className="index_funds_item col sensex"
          onMouseEnter={handleSensexMouseEnter}
          onMouseLeave={handleSensexMouseLeave}
        >
          <div className="company_name">
            <p>SENSEX</p>
            <p className="lower_p">BSE INDEX</p>
          </div>
          {!isSensexHovered && (
            <div className="company_price">
              <p>{sensexData ? sensexData.last_price : "-"}</p>
              <p className="lower_p">
                {sensexData ? sensexData.net_change : "-"}
              </p>
            </div>
          )}
          {isSensexHovered && (
            <div className="company_buttons">
              <AiOutlineLineChart
                size={18}
                onClick={() => handleChart("BSE_INDEX|SENSEX")}
              />
              <AiOutlineBars
                size={18}
                onClick={() => handleOption("BSE_INDEX|SENSEX")}
              />
              <AiOutlineInfoCircle
                size={18}
                onClick={() => handleMarketQuote("BSE_INDEX|SENSEX")}
              />
            </div>
          )}
        </div>
      </div>
      <div className="input_div input-group input-group-sm mb-3">
        <input
          type="text"
          className="form-control"
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
          placeholder="search companies"
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
      </div>
      <hr />
      <div className="companies-list">
        <Company data={companiesToRender} companyData={companyData} />
      </div>
    </div>
  );
}

export default Companies;
