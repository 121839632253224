import React, { useState, useContext } from "react";


import Companies from "../Company/Companies/Companies";
import CandlestickChartPage from "../CandleStick/CandlestickChartPage";
import OptionChainTable from "../OptionChain/OptionChainTable";
import MarketQuote from "../MarketQuote/MarketQuote";
import "./Home.scss";
import { Context } from "../../context";

function Home() {
  const {
    instrumentKey,
    showCandlestickChart,
    setShowCandlestickChart,
    showMarketQuote,
    setShowMarketQuote,
    selectedInstrumentKey,
    setSelectedInstrumentKey,
  } = useContext(Context);

  const toggleChart = () => {
    setShowCandlestickChart(!showCandlestickChart);
  };
  return (
    <div className="home-container">
      <div className="companies-container">
        <Companies
          onSelectInstrument={() => setSelectedInstrumentKey(instrumentKey)}
        />
      </div>
      <div className="chart-container">
        <div className="chart_home">
          <div className="chart_section">
            <div className="logo_section">
              <div className="logo">
                <a href="https://www.letsdotrade.com/">
                  <img
                    src="https://www.letsdotrade.com/wp-content/uploads/2024/05/0978d924-8c48-4b42-b126-feb596cf83d2-260x158.webp"
                    alt=""
                  />
                </a>
              </div>
              <div className="btn_section">
                <a href="https://www.letsdotrade.com/">
                  <button className="btn btn-dark btn-sm">Home</button>
                </a>
                <a href="https://www.letsdotrade.com/contact-us/">
                  <button className="btn btn-dark btn-sm">Contact Us</button>
                </a>
              </div>
            </div>

            {showCandlestickChart ? (
              <CandlestickChartPage />
            ) : (
              <OptionChainTable />
            )}
          </div>
          <div className="quote_section">
            {showMarketQuote && (
              <MarketQuote
                instrumentKey={selectedInstrumentKey}
                setShowMarketQuote={setShowMarketQuote}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
