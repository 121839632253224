import React, { useState, useContext } from "react";
import {
  AiOutlineLineChart,
  AiOutlineBars,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import "./Company.scss";

import { Context } from "../../context";

function Company({ data, companyData }) {
  const {
    setInstrumentKey,
    setShowCandlestickChart,
    setShowMarketQuote,
    setSelectedInstrumentKey,
  } = useContext(Context);
  const [hoveredCompany, setHoveredCompany] = useState(null);

  const handleMouseEnter = (item) => {
    setHoveredCompany(item);
  };

  const handleMouseLeave = () => {
    setHoveredCompany(null);
  };

  const handleChart = (item) => {
    console.log(item.instrument_key);
    setInstrumentKey(item.instrument_key);
    setShowCandlestickChart(true);
    setSelectedInstrumentKey(item.instrument_key);
  };
  const handleOption = (item) => {
    console.log(item.instrument_key);

    setInstrumentKey(item.instrument_key);
    setShowCandlestickChart(false);
    setSelectedInstrumentKey(item.instrument_key);
  };
  const handleMarketQuote = (item) => {
    console.log(item.instrument_key);

    setInstrumentKey(item.instrument_key);
    setShowMarketQuote(true);
    setSelectedInstrumentKey(item.instrument_key);
  };

  return (
    <div className="company">
      {data.map((item, index) => {
        let name = item.segment + ":" + item.trading_symbol;
        let companyInfo = companyData[name];

        return (
          <div
            key={index}
            className="company-item"
            onMouseEnter={() => handleMouseEnter(item)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="company_details">
              <div className="company_name">
                <p>{item.short_name}</p>
                <p className="lower_p">{item.segment}</p>
              </div>

              {hoveredCompany === item ? (
                <div className="company_buttons">
                  <AiOutlineLineChart
                    size={20}
                    onClick={() => handleChart(item)}
                  />{" "}

                  <AiOutlineBars
                    size={20}
                    onClick={() => handleOption(item)}
                  />{" "}

                  <AiOutlineInfoCircle
                    size={20}
                    onClick={() => handleMarketQuote(item)}
                  />{" "}

                </div>
              ) : (
                <div className="company_price">
                  <p>{companyInfo && companyInfo.last_price}</p>
                  <p className="lower_p">
                    {companyInfo && companyInfo.net_change}
                  </p>
                </div>
              )}
            </div>
            <hr />
          </div>
        );
      })}
    </div>
  );
}

export default Company;
